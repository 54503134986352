import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { CenteredFlex, Container, primaryColor, ReviewStyle, } from '../App.Style';
import { InfinitySpin } from  'react-loader-spinner'
import queryString from 'query-string';


const ReviewPage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  let { page_slug } = useParams();

  useEffect(() => {
    document.documentElement.scrollTo({top: 0, left: 0, behavior: "instant"});
    setLoading(true);
    fetch('https://best4apps.com/api/review-full/', {
      method: 'POST',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: queryString.stringify({
          'slug': 'gamblingit',
          'page_slug':page_slug,
      }),
    })
    .then(response => response.json())
    .then(json => {
        setData(json)
        setLoading(false);
        // console.log(json)
    })
  }, [page_slug]);

  if (loading){
    return <CenteredFlex>
        <InfinitySpin 
            width='200'
            color={primaryColor}
        />
    </CenteredFlex>
  }

  return (
    <Container className='template-product-review'>
      <ReviewStyle dangerouslySetInnerHTML={{__html: data.descrizione}}></ReviewStyle>
    </Container>
  )
}

export default ReviewPage