import React, { useState } from 'react';

import { FaRegThumbsUp, FaRegThumbsDown, FaThumbsUp, FaThumbsDown, FaInfoCircle, FaStar } from "react-icons/fa";
import { Rating } from 'react-simple-star-rating'
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import { useMediaQuery } from "react-responsive"

import { SiteButtonStyle, SiteCenterStyle, SiteTopStyle, SiteHomeStyle, ImageSiteHomeStyle, BottomSiteHomeStyle, BonusHomeStyle, AttributeSiteHomeStyle, ButtonSiteHomeStyle, TriangleSiteHomeStyle } from '../App.Style';


const SiteHome = props => {
    const [site, setSite] = useState(props.element);
    const isMobile = useMediaQuery({ maxWidth: 576 })

    const addLike = async dislike => {
        const fingerprint = await getCurrentBrowserFingerPrint()
        fetch('https://best4apps.com/api/do_like/', {
            method: 'POST',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: queryString.stringify({
                'id_el': site.id,
                'id_tel': fingerprint,
                'dislike': dislike
            }),
        })
        .then(response => response.json())
        .then(json => {
            setSite({...site, like:json.like, dislike:json.dislike, action:json.action})
        })
    }

    const ICON_SIZE = '1.5em'

    let like_icon = <FaRegThumbsUp size={ICON_SIZE} />
    let dislike_icon = <FaRegThumbsDown size={ICON_SIZE} />
    if (site.action == 'like'){
      like_icon = <FaThumbsUp size={ICON_SIZE} />
    } else if (site.action == 'dislike'){
      dislike_icon = <FaThumbsDown size={ICON_SIZE} />
    }

    const attributes = site.casino_attr.map(attr=>{
      return (
        <div key={site.nome_sito+attr.name}>{attr.name}: <strong>{attr.value}</strong></div>
      )
    })

    const discount = site.sconto.replace(/([0-9]+\.?[0-9]*)(%|€|$)*/g, '<strong>$1$2</strong>')

    let content = ''
    if (isMobile){
      content = (
        <div>
          <SiteTopStyle>
            <img src={'https://best4apps.com'+site.icon} />
            <div>
              <h3>{site.nome_sito}</h3>
              <div>
                <span>{site.rating}</span>
                <Rating
                  iconsCount={5}
                  initialValue={site.rating}
                  readonly={true}
                  size={20}
                  allowFraction={true}
                />
              </div>
            </div>
          </SiteTopStyle>
          <SiteCenterStyle>
            <div>
              <div onClick={()=>addLike(false)}>
                {like_icon}
                <span>{site.like}</span>
              </div>
              <div onClick={()=>addLike(true)}>
                {dislike_icon}
                <span>{site.dislike}</span>
              </div>
            </div>
            <Link to='/review/'>
              <FaInfoCircle size={ICON_SIZE} />
              <span>Review</span>
            </Link>
          </SiteCenterStyle>
          <span>{site.sottotitolo}</span>
          <SiteButtonStyle href={site.url} target='_blank' >
            <span>Visita il sito</span>
          </SiteButtonStyle>
        </div>
      )
    } else {
        content = (
          <SiteHomeStyle>
            <TriangleSiteHomeStyle><span>{props.index+1}</span></TriangleSiteHomeStyle>
            <ImageSiteHomeStyle>
              <img src={'https://best4apps.com'+site.icon} />
              <div>
                <FaStar size={15} color={'orange'}/><strong>{site.rating}</strong>/5
              </div>
            </ImageSiteHomeStyle>
            <BonusHomeStyle>
              <div dangerouslySetInnerHTML={{__html: discount}}></div>
            </BonusHomeStyle>
            <AttributeSiteHomeStyle>
              {attributes}
            </AttributeSiteHomeStyle>
            <ButtonSiteHomeStyle>
              <a href={site.url} target='_blank' >
                <span>Visita il sito</span>
              </a>
            </ButtonSiteHomeStyle>
            <BottomSiteHomeStyle to={'/review/'+site.slug}>
              Vai alla recensione
            </BottomSiteHomeStyle>
          </SiteHomeStyle>
        )
    }

    return (
      <>
        {content}
      </>
    );
}


export default SiteHome;
