import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';


import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";

import ReviewPage from './pages/ReviewPage';
import HomePage from './pages/HomePage';
import { GlobalStyle } from './App.Style';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import CookiePage from './pages/CookiePage';
import NotFoundPage from './pages/NotFoundPage';


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
            {
                path: "review",
                element: <ReviewPage />,
                children: [
                    {
                        path: ":page_slug",
                        element: <ReviewPage />,
                    },
                ]
            },
            {
                path: "termini",
                element: <TermsPage />,
            },
            {
                path: "cookie",
                element: <CookiePage />,
            },
            {
                path: "privacy",
                element: <PrivacyPage />,
            },
            {
                path: "*",
                element: <NotFoundPage />,
            },
        ]
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <GlobalStyle />
        <RouterProvider router={router} />
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
