import React from 'react'
import { Container , FooterStyle} from '../App.Style'


const Footer = () => {
  return (
    <FooterStyle>
      <Container>
        <div>
          <p><a href='/termini'>Termini e condizioni</a> | <a href='/privacy'>Privacy Policy</a> | <a href='/cookie'>Cookie Policy</a></p>
          <p><img src='/img/aams.png' />Il Gioco è vietato ai minori e può creare dipendenza patologica.</p>
          <p>miglioribonus.net confronta le quote e i bonus offerti dai Bookmakers, in possesso di regolare concessione ad operare in Italia rilasciata dall’ADM. Il servizio, come indicato dall’AGCOM al punto 5.6 delle proprie Linee Guida (allegate alla delibera 132/19/CONS), è effettuato nel rispetto del principio di continenza, non ingannevolezza e trasparenza e non costituisce pertanto una forma di pubblicità.</p>
        </div>
        <div>
          <p><img src='/img/ISS.png'/> Il Telefono Verde Nazionale per le problematiche legate al Gioco d’Azzardo (TVNGA) - 800 558822</p>
          <p><img src='/img/18+.png'/>Il gioco è vietato ai minori di anni 18 - può causare dipendenza.</p>
          <p><a href='https://www.begambleaware.org/' target="_blank"><img src='/img/gamble-aware.svg'/></a></p>
        </div>
        <p>Diritto D'autore © Tutti i diritti riservati. Utilizzando i nostri contenuti, prodotti e servizi, accetti la nostra Informativa sulla privacy. La riproduzione totale o parziale è severamente vietata. I giocatori devono avere almeno 18 anni. Questo sito Web contiene informazioni su una gamma di prodotti e servizi. Alcuni dettagli, inclusi prezzi e offerte speciali, ci vengono forniti direttamente dai nostri partner. Questi sono soggetti a modifiche in qualsiasi momento senza preavviso. Le informazioni che condividiamo non costituiscono consulenza o previsione legale o professionale e non devono essere trattate come tali.</p>
      </Container>
    </FooterStyle>
  )
}

export default Footer