import React, { useState, useEffect } from 'react';
import './imported.css';

import CookieConsent from "react-cookie-consent";
import Header from './components/Header';
import Footer from './components/Footer';
import { Link, Outlet, useLocation } from "react-router-dom";
import { AgePopupStyle } from './App.Style';


function App() {
  const [age, setAge] = useState(localStorage.getItem('age'));
  const location = useLocation();

  let deferredPrompt;

  window.addEventListener('beforeinstallprompt', (e) => {
      deferredPrompt = e;
  });

  useEffect(()=>{
      localStorage.setItem('age', age)
  },[age]);


  const createLink = async () => {
    if (deferredPrompt !== null) {
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        if (outcome === 'accepted') {
            deferredPrompt = null;
        }
    }
  }
  //
  const agePopup = (
    <AgePopupStyle>
      <div>
        <div>Real Money Casino</div>
        <div>
          <p>Hai almeno 18 anni?</p>
          <span>Devi avere più di 18 anni e accettare i termini in basso prima di entrare in questo sito.</span>
          <div>
            <button onClick={()=>setAge('true')}>Entra</button>
            <button onClick={()=>window.location.href = "https://google.it"}>Esci</button>
          </div>
          <Link to="/termini" target='_blank'>Termini e Condizioni</Link>
          {/* <button onClick={createLink}>Salva</button> */}
        </div>
      </div>
    </AgePopupStyle>
  )


  return (
    <>
      <CookieConsent
        buttonText="Accetta cookie"
        declineButtonText='Rifiuta cookie'
        enableDeclineButton={true}
        flipButtons
        style={{ background: "#11181f" }}
        buttonStyle={{ color: "white", fontSize: "1em", background:"orange" }}
        declineButtonStyle={{ color: "white", fontSize: "0.8em", }}
      >
        Questo sito utilizza cookie per migliorare l'esperienza di utilizzo dell'utente.
      </CookieConsent>
      {age!='true' && location.pathname!='/termini' && agePopup}
      <Header />
      <Outlet/>
      <Footer />
    </>
  );
}

export default App;
