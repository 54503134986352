import React from 'react'
import { Container, HeaderStyle } from '../App.Style'
import { Link } from "react-router-dom";


const Header = () => {
  return (
    <HeaderStyle>
        <Container>
          <Link to='/'>
            <h2>MiglioriBonus.net</h2>
          </Link>
        </Container>
    </HeaderStyle>
  )
}

export default Header