import styled from "styled-components/macro";
import { createGlobalStyle } from 'styled-components'

import { Link } from "react-router-dom";


export const primaryColor = 'orange'
const grayColor = '#e4edf2'
const grayLightColor = '#f5f7f9'
const fontColor = '#4d606f'
const numberColor = '#dc3545'

const mobile = '@media (max-width: 576px)'


// style pagina review 
export const GlobalStyle = createGlobalStyle`
    #root{
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
    /* .body_content{
        *, ::after, ::before {
            box-sizing: border-box;
        }
        .flex-row {
            flex-direction: row !important;
        }
        .d-flex {
            display: flex !important;
        }
        .justify-content-center {
            justify-content: center !important;
        }
        .text-decoration-none {
            text-decoration: none !important;
        }
        .row {
            display: flex;
            flex-wrap: wrap;
            margin-left: -15px;
            margin-right: -15px;
        }
        .col {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
        .col-md-8, .col-md-4, .col-5, .col-7, .col-3, .col-9, .col-6, .col-sm-3, .col-sm-6, .col-md-5, .col-sm-7, .col-md-7{
            padding-left: 15px;
            padding-right: 15px;
            position: relative;
            width: 100%;
        }
        .col-md-8 {
            flex: 0 0 66.66666667%;
            max-width: 66.66666667%;
        }
        .col-md-4 {
            flex: 0 0 33.33333333%;
            max-width: 33.33333333%;
        }
        .col-5, .col-md-5 {
            flex: 0 0 41.66666667%;
            max-width: 41.66666667%;
        }
        .col-7, .col-sm-7 , .col-md-7{
            flex: 0 0 58.33333333%;
            max-width: 58.33333333%;
        }
        .col-3, .col-sm-3 {
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-9 {
            flex: 0 0 75%;
            max-width: 75%;
        }
        .col-6, .col-sm-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-12 {
            flex: 0 0 100%;
        }
        .col-md {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
        .m-0 {
            margin: 0 !important;
        }
        .ml-0, .mx-0 {
            margin-left: 0 !important;
        }
        .mr-0, .mx-0 {
            margin-right: 0 !important;
        }
        .mb-0, .my-0 {
            margin-bottom: 0 !important;
        }
        .mt-0, .my-0 {
            margin-top: 0 !important;
        }
        .mr-2, .mx-2 {
            margin-right: .5rem !important;
        }
        .mt-3, .my-3 {
            margin-top: 1rem !important;
        }
        .pl-0, .px-0 {
            padding-left: 0 !important;
        }
        .pr-0, .px-0 {
            padding-right: 0 !important;
        }
        .pb-1, .py-1 {
            padding-bottom: .25rem !important;
        }
        .p-2 {
            padding: .5rem !important;
            padding-left: 0.5rem;
        }
        .p-3 {
            padding: 1rem !important;
        }
        .pl-3, .px-3 {
            padding-left: 1rem !important;
        }
        .pb-3, .py-3 {
            padding-bottom: 1rem !important;
        }
        .pt-3, .py-3 {
            padding-top: 1rem !important;
        }
        .pl-4, .px-4 {
            padding-left: 1.5rem !important;
        }
        .pl-lg-0, .px-lg-0 {
            padding-left: 0 !important;
        }
        .pr-lg-0, .px-lg-0 {
            padding-right: 0 !important;
        }
        .h3, h3 {
            font-size: 1.75rem;
        }
        .bg-white {
            background-color: #fff !important;
        }
        .text-center {
            text-align: center !important;
        }
        .table {
            color: #212529;
            margin-bottom: 1rem;
            width: 100%;
        }
        .table-responsive {
            display: block;
            overflow-x: auto;
            width: 100%;
        }
        .accordion {
            margin-bottom: 20px;
            overflow-anchor: none;
        }
        .accordion .card {
            word-wrap: break-word;
            background-clip: border-box;
            background-color: #fff;
            border: none;
            border-radius: .25rem;
            display: flex;
            flex-direction: column;
            font-weight: 100;
            min-width: 0;
            position: relative;
        }
        .accordion > .card:not(:last-of-type) {
            border-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        .accordion > .card > .card-header {
            border-radius: 0;
            margin-bottom: -1px;
        }
        .accordion .card .card-header {
            border-bottom: 1px solid rgba(0,0,0,.125);
            margin-bottom: 0;
            padding: .75rem .25rem;
        }
        .accordion .justify-content-between {
            justify-content: space-between !important;
        }
        .accordion .card .card-body {
            flex: 1 1 auto;
            font-weight: 400;
            padding: 1.25rem;
        }
        h3 {
            font-size: 1.424rem;
        }
        .accordion h3 {
            font-weight: 400;
        }
        article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
           display: block;
        }
        .review .cms-table, .review .table-bordered {
            border-collapse: separate;
            border-spacing: 5px;
        }
        .table .thead-light th, .table-bordered > tbody > tr > th, .table-content th, .table-striped > tbody > tr > th, .table > thead > tr > th {
            background-color: #ecedef;
            border: solid #ddd;
            border-width: 0 0 1px;
            color: #333;
            font-size: 12px;
            letter-spacing: .8px;
            line-height: 26px;
            padding: 8px 20px !important;
            text-align: left;
            text-transform: uppercase;
            vertical-align: top !important;
        }
        .review .cms-table tbody tr td:first-child, .review .table-bordered tbody tr td:first-child {
            background-color: #e2e1e1;
        }
        .review .table-responsive .table tbody tr td, .review .table-responsive table tbody tr td {
            overflow-wrap: break-word;
            text-align: left;
            white-space: normal;
        }
        .review .cms-table tbody tr td, .review .table-bordered tbody tr td {
            background-color: #ececec;
            border-radius: 4px;
            overflow-wrap: break-word;
            padding: 8px 15px !important;
            text-align: left;
            white-space: normal;
        }
        .table-bordered > tbody > tr > td, .table-content td, .table > tbody > tr > td {
            border: solid #ddd;
            border-width: 0 0 1px;
            color: #333;
            padding: 8px 20px !important;
            text-align: left;
        }
        .review .cms-table tbody tr td:first-child, .review .table-bordered tbody tr td:first-child {
            background-color: #e2e1e1;
        }
        .promotions_table {
            font-size: .8em;
            margin-bottom: 1em;
            margin-top: 1em;
        }
        .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
            margin-left: auto;
            margin-right: auto;
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
        }
        section.promotions_table .promo_table_row:first-child {
            background-color: #333;
        }
        section.promotions_table .promo_table_row {
            background: 0 0;
            background-color: #ececec;
            border: none;
            border-radius: 4px;
            margin-bottom: 5px;
        }
        .promotions_table .promo_table_row:first-child {
            color: #505050;
            height: 4em;
        }
        p {
            margin-top: 0;
        }
        section.promotions_table .promo_table_row {
            background: 0 0;
            background-color: #ececec;
            border: none;
            border-radius: 4px;
            margin-bottom: 5px;
        }
        .promotions_table .promo_table_row:not(:first-child) {
            height: auto !important;
        }
        .promotions_table .promo_table_row:not(:first-child) .promo_table_col {
            border-bottom: 1px solid #d8d8d8;
            height: 4.8em;
            padding-left: 0;
            padding-right: 0;
        }
        .promotions_table .promo_table_row {
            background: #fff;
            border-left: 1px solid #d8d8d8;
            border-right: 1px solid #d8d8d8;
            color: #1c1c1c;
            height: 4.8em;
            padding: 0 1em;
        }
        .row-cols-5 > * {
            flex: 0 0 20%;
            max-width: 20%;
        }
        .promotions_table .promo_table_row .promo_table_col .promotions_table_cell .cell_inner {
            align-items: center;
            display: flex;
            float: none;
            height: 100%;
            justify-content: center;
            padding: 0 5px;
            vertical-align: middle;
            word-break: break-word;
        }
        .promotions_table .promo_table_row .promo_table_col .promotions_table_cell .cell_inner p {
            font-size: 1em;
            font-weight: 600;
            margin-bottom: 0;
        }
        section.promotions_table .promo_table_row:first-child p {
            color: #fff;
        }
        .promotions_table .promo_table_row .promo_table_col .promotions_table_cell {
            height: inherit;
            width: 100%;
        }
        .promotions_table .promo_table_row .promo_table_col {
            height: inherit;
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
            text-align: center;
        }
        .widget_column {
            padding-right: 0;
        }
        .widget_column .company_info {
            margin-bottom: 1em;
            padding-bottom: 1em;
        }
        .widget_column .container-fluid {
            padding-left: 8px;
            padding-right: 8px;
        }
        .widget_column .company_info h3 {
            background-color: #333;
            border-radius: 4px;
            color: #fff;
            margin-bottom: 5px;
            padding: 8px 0 8px 12px;
        }
        .widget_column .company_info .company_info_inner .company_info_row {
            word-wrap: break-word;
            background-color: #fff;
        }
        .no-gutters {
            margin-left: 0;
            margin-right: 0;
        }
        .widget_column .company_info .company_info_inner .company_info_row .company_info_col:nth-child(2n+1) {
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
            padding-bottom: 8px;
            padding-left: 15px;
            padding-top: 8px;
        }
        .widget_column .company_info .company_info_inner .company_info_row .company_info_col {
            background-color: #ececec;
            margin-bottom: 5px;
            padding-left: 0;
        }
        .clearfix::after {
            clear: both;
            content: "";
            display: block;
        }
        .widget_column .company_info .company_info_inner .company_info_row .company_info_col p {
            margin: 0;
        }
        .widget_column .company_info .company_info_inner .company_info_row {
            word-wrap: break-word;
            background-color: #fff;
        }
        .btn {
            background-color: transparent;
            border: 1px solid transparent;
            border-radius: .25rem;
            color: #212529;
            display: inline-block;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            padding: .375rem .75rem;
            text-align: center;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            user-select: none;
            vertical-align: middle;
        }
        .btn-cta-play-now, .button-blue, .button-green, .button-yellow {
            border: 0;
            border-radius: 4px;
            display: block;
            font-size: 16px;
            font-weight: 700;
            height: 54px;
            line-height: 54px;
            text-align: center;
            text-transform: uppercase;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        .btn-cta-play-now {
            background: ${primaryColor};
            color: #fff;
        }
        .btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
            background-color: ${primaryColor};
            color: #fff;
        }
        .btn-group-sm > .btn, .btn-sm {
            border-radius: .2rem;
            font-size: .875rem;
            line-height: 1.5;
            padding: .25rem .5rem;
        }
        .btn-primary {
            background-color: ${primaryColor};
            color: #fff;
        }
        .widget_column .company_info .company_info_inner .company_info_row:last-child .company_info_col:nth-child(2n+1) {
            padding: 0;
        }
        .widget_column .company_info .company_info_inner .company_info_row .company_info_col:nth-child(2n) {
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
            padding-bottom: 8px;
            padding-right: 15px;
            padding-top: 8px;
        }
        .widget_column section.supported_platforms_table .right_col_row:first-child {
            padding: 0;
        }
        .widget_column section.supported_platforms_table {
            border: none;
            margin: 0;
            padding: 0 8px;
        }
        .widget_column section.supported_platforms_table .row h3 {
            background-color: #333;
            border-radius: 4px;
            color: #fff;
            margin: 0;
            padding: 8px 0 8px 15px;
            width: 100%;
        }
        .widget_column section.supported_platforms_table .row .sidebar_Icons {
            background-color: #ececec;
            margin: 0 0 5px;
            padding: 8px 0;
            padding-left: 0px;
        }
        .widget_column section.supported_platforms_table .row .sidebar_Icons:nth-child(2n+1) {
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            padding-left: 5px;
        }
        .widget_column section.dual_side_news_table .sidebar_Icons .sidebar_Icons_text .platform_text, .widget_column section.side_navigation_table .sidebar_Icons .sidebar_Icons_text .platform_text, .widget_column section.side_news_table .sidebar_Icons .sidebar_Icons_text .platform_text, .widget_column section.supported_platforms_table .sidebar_Icons .sidebar_Icons_text .platform_text {
            display: table;
            height: 100%;
        }
        .widget_column section.dual_side_news_table .sidebar_Icons .sidebar_Icons_text .platform_text p, .widget_column section.side_navigation_table .sidebar_Icons .sidebar_Icons_text .platform_text p, .widget_column section.side_news_table .sidebar_Icons .sidebar_Icons_text .platform_text p, .widget_column section.supported_platforms_table .sidebar_Icons .sidebar_Icons_text .platform_text p {
            display: table-cell;
            float: none;
            font-size: 1.2em;
            vertical-align: middle;
        }
        .widget_column section.dual_side_news_table > .right_col_row, .widget_column section.side_navigation_table > .right_col_row, .widget_column section.side_news_table > .right_col_row, .widget_column section.supported_platforms_table > .right_col_row {
            font-weight: 600;
            padding: 5px 0;
        }
        .widget_column .other_products_table .row h3 {
            background-color: #333;
            border-radius: 4px;
            color: #fff;
            margin: 0 0 5px;
            padding: 8px 0 8px 15px;
            width: 100%;
        }
        .widget_column section.other_products_table > .related_products_row:nth-child(2n) {
            background-color: #f5f5f5;
        }
        .widget_column section.other_products_table > .related_products_row {
            font-size: .7em;
            font-weight: 600;
            padding: .5em 0;
        }
        .widget_column section.dual_side_news_table .sidebar_Icons, .widget_column section.side_navigation_table .sidebar_Icons, .widget_column section.side_news_table .sidebar_Icons, .widget_column section.supported_platforms_table .sidebar_Icons {
            font-size: .7em;
        }
        .template-product-review .widget_column section.supported_platforms_table .row .sidebar_Icons {
            background-color: #ececec;
            margin: 0 0 5px;
            padding: 8px 0;
        }
        .widget_column section.other_products_table .related_products_english .related_products_icon, .widget_column section.other_products_table .related_products_non_english .related_products_icon {
            font-size: 2em;
            padding: 0;
        }
        .widget_column section.other_products_table .related_products_english .related_product_type .platform_text p, .widget_column section.other_products_table .related_products_non_english .related_product_type .platform_text p {
            display: table-cell;
            float: none;
            font-size: 14px;
            vertical-align: middle;
        }
        .widget_column section.other_products_table > .related_products_row .related_products_non_english a {
            color: #1c1c1c;
            font-weight: 600;
        }
        .widget_column section.dual_side_news_table > .right_col_row, .widget_column section.side_navigation_table > .right_col_row, .widget_column section.side_news_table > .right_col_row, .widget_column section.supported_platforms_table > .right_col_row {
            font-weight: 600;
        }
        .img-fluid {
            display: block;
            height: auto;
            max-width: 100%;
        }
        .promotions_table .promo_table_row {
            background: #fff;
            border-left: 1px solid #d8d8d8;
            border-right: 1px solid #d8d8d8;
            color: #1c1c1c;
            height: 4.8em;
            padding: 0 1em;
        }
        .promotions_table .promo_table_row:not(:first-child) {
            height: auto !important;
        }
        section.promotions_table .promo_table_row {
            background: 0 0;
            background-color: #ececec;
            border: none;
            border-radius: 4px;
            margin-bottom: 5px;
        }
        .template-product-review section.promotions_table .promo_table_row .promo_table_col {
            border: none;
        }
        .operator-item-v2 {
            -webkit-animation: fadeAnimation .3s;
            background-color: #fff !important;
            border: 1px solid rgba(51,51,51,.25);
            border-radius: 10px !important;
            box-shadow: 0 2px 3px rgba(3,15,39,.12);
            margin-bottom: 16px !important;
        }
        .operator-list .operator-item, .operator-list .single-offer-item, .single-offer .operator-item, .single-offer .single-offer-item {
            background: #ececec;
            border-radius: 4px;
            list-style: none;
            margin-bottom: 10px;
            position: relative;
        }
        .operator-item-v2 .operator-main {
            padding-bottom: 20px;
            padding-top: 10px;
        }
        .operator-item-v2 .operator-main .operator-column-logo-v2 {
            padding-left: 10px;
            text-align: center;
            vertical-align: middle;
        }
        .operator-item-v2 .operator-main .logo-wrapper {
            width: -moz-max-content;
            width: max-content;
        }
        .operator-list .operator-item .read-review-link, .operator-list .single-offer-item .read-review-link, .single-offer .operator-item .read-review-link, .single-offer .single-offer-item .read-review-link {
            background-image: url(https://objects.kaxmedia.com/auto/o/139759/a2938e1de1.svg);
            background-position: 100%;
            background-repeat: no-repeat;
            background-size: 6px;
            color: #dc3545;
            display: inline-block;
            float: right;
            font-size: 12px;
            font-weight: 700;
            height: auto;
            margin-right: 1em;
            padding: 2px 10px 2px 0;
            text-align: right;
            top: 10px;
            width: auto;
            z-index: 997;
        }
        .operator-list .operator-item .read-review-link.review-link-v2, .operator-list .single-offer-item .read-review-link.review-link-v2, .single-offer .operator-item .read-review-link.review-link-v2, .single-offer .single-offer-item .read-review-link.review-link-v2 {
            background-image: url(https://objects.kaxmedia.com/auto/o/139703/8a8d4cc06f.svg);
            color: ${primaryColor} !important;
            font-weight: 400;
        }
        .operator-item-v2 .operator-main .operator-column-bonus-v2 {
            margin: 10px auto auto;
            padding: 20px 30px;
        }
        .operator-item-v2 .offer-description {
            font-weight: 600;
            text-align: center;
        }
        .operator-item-v2 .operator-main .operator-playnow-column-v2 {
            margin-left: auto;
            margin-right: 27px;
        }
        .operator-item-v2 .operator-main .operator-playnow-column-v2 {
            margin-top: 32px;
        }
        .operator-item-v2 .payment-provider {
            margin-bottom: 5px;
            margin-left: 2px;
            margin-right: 2px;
        }
        .operator-item-v2 .button-blue-v2 {
            background: ${primaryColor};
            border: 0;
            border-radius: 4px;
            color: #fff;
            display: block;
            font-size: 16px;
            font-weight: 600;
            line-height: 50px;
            text-align: center;
            text-transform: uppercase;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        .operator-item-v2 .operator-main .button-blue-v2 {
            margin: auto;
            width: 306px;
        }
        .operator-item-v2 .read-terms-link-v2 {
            border-top: 1px solid rgba(51,51,51,.25);
            color: #777;
            font-size: 11px;
            line-height: 16px;
            padding: 10px;
            display: block;
        }
        .operator-item-v2 .operator-established-year-v2 {
            color: #777;
            font-size: 12px;
            text-align: center;
            width: 100%;
        }
        .terms-and-conditions, .termsLine {
            color: #777;
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
        }
        .operator-list .operator-item .terms-and-conditions, .operator-list .single-offer-item .terms-and-conditions, .single-offer .operator-item .terms-and-conditions, .single-offer .single-offer-item .terms-and-conditions {
            display: block;
            padding: 4px 8px 8px;
            text-align: left;
        }
        .operator-item-v2 .offer-description em {
            color: ${numberColor};
        }
        .play_now_btn {
            background: ${primaryColor};
            border-radius: 0;
            color: #fff;
            display: inline-block;
            font-size: 12px;
            font-weight: 700;
            margin-bottom: 0;
            padding: 7px 3px;
            text-align: center;
            vertical-align: middle;
            width: 100%;
        }
        .play_now_btn {
            font-size: 14px;
        }
        .visit_btn {
            padding: .4em;
            width: 100%;
        }
        .play_now_btn:active, .play_now_btn:focus, .play_now_btn:hover {
            background: ${primaryColor};
            color: #fff;
            text-decoration: none;
        }
        .promotions_table .promo_table_row:not(:first-child) {
            height: auto !important;
        }
        .template-product-review section.promotions_table .promo_table_row {
            background: 0 0;
            background-color: #ececec;
            border: none;
            border-radius: 4px;
            margin-bottom: 5px;
        }
        .promotions_table .promo_table_row:not(:first-child) .promo_table_terms {
            border-top: 1px solid #d8d8d8;
            color: #a9a9a9;
            font-size: 10px;
            height: auto;
            padding: 10px 0;
        }
        .template-product-review section.promotions_table .promo_table_row .promo_table_terms {
            border: none;
            border-top: 1px solid #d8d8d8;
            padding-right: 0;
        }
        #anchor-menu-sidebar-container {
            display: block;
            margin-bottom: 0;
            padding-bottom: 10px;
        }
        .card-header {
            background-color: rgba(0,0,0,.03);
            border-bottom: 1px solid rgba(0,0,0,.125);
            margin-bottom: 0;
            padding: .75rem 1.25rem;
        }
        .card-header:first-child {
            border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
        }
        #anchor-menu-heading {
            background-color: #333;
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            padding-left: 10px;
        }
        .anchor-menu-content {
            border: 1px solid #ecedef;
            color: #dc3545;
            font-size: 18px;
            font-weight: 400;
        }
        .body_content ol, .body_content ul {
            margin-bottom: 10px;
            width: 100%;
        }
        ol.cc-list-with-thumbnail, ul.cc-list-with-thumbnail {
            margin: 0;
            padding: 0;
        }
        ol.cc-list-with-thumbnail li, ul.cc-list-with-thumbnail li {
            align-items: center;
            display: flex;
            list-style: none !important;
            margin-bottom: 20px !important;
        }
        ol.cc-list-with-thumbnail li img, ul.cc-list-with-thumbnail li img {
            clear: both;
            float: left;
            margin-right: 20px;
        }
        p {
            color: #333;
            letter-spacing: 0;
            margin-bottom: 1rem;
        }
    }
    // TOP
    .review_hero_unit {
        background: linear-gradient(180deg,#505050 1%,#333);
        border-top: 1px solid #505050;
        box-shadow: 0 .3em 2px #000;
        font-size: inherit;
        margin-bottom: 0;
        padding: 11px !important;
        position: relative;
        text-align: center;
        .operator_review_hero {
            background: #000;
            border: 1px solid #000;
            display: inline-block;
            padding: 0;
            position: relative;
            width: 100%;
        }
        .operator_review_hero {
            display: flex;
        }
        .bonus_col .row .bonus_desc_col {
            padding: 1em 1em 0;
        }
        .bonus_col .container-fluid .row .bonus_desc_col span.review-offer {
            color: #fff;
            display: block;
            font-family: Poppins Bold,Helvetica,Arial,sans-serif;
            font-size: 43px;
            line-height: 43px;
            margin-bottom: .31em;
        }
        .operator_review_hero > .logo_col .container-fluid img {
            height: inherit;
            margin: 0 auto;
            max-height: 187px;
        }
        .operator-product-header-terms-conditions {
            color: #fff;
            font-size: 10px;
            line-height: 14px;
            padding: .5em 0;
            text-align: right;
            text-align: center;
        }
        .logo_col {
            background: #000;
        }
        .bonus_col, .review_hero_unit .logo_col {
            padding-left: 8px;
            padding-right: 8px;
        }
        .operator_review_hero > .logo_col {
            height: 100%;
        }
        .bonus_col {
            background-color: #333;
            border-left: .6em solid #1c1c1c;
            display: table;
            height: auto;
            width: auto;
        }
        .bonus_col {
            border-left: .6em solid #1c1c1c;
            width: 58.33333333% !important;
        }
        .bonus_col .row .bonus_desc_col {
            padding: 1em 1em 0;
        }
        .play_now_btn:active, .play_now_btn:focus, .play_now_btn:hover {
            background: ${primaryColor};
            color: #fff;
            text-decoration: none;
        }
        .play_now_row .play_now_btn {
            padding-left: .75em;
            padding-right: .75em;
            text-transform: uppercase;
        }
        .play_now_row .play_now_btn {
            width: 295px;
        }
        .play_now_btn {
            background: ${primaryColor};
            border-radius: 0;
            color: #fff;
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 0;
            padding: 7px 3px;
            text-align: center;
            vertical-align: middle;
            width: 100%;
        }
        .bonus_col .container-fluid .row .bonus_desc_col span.review-offer em {
            color: ${numberColor};
            font-style: normal;
        }
        .bonus_col .container-fluid, .review_hero_unit .logo_col .container-fluid {
            display: table-cell;
            padding-left: .25em;
            padding-right: .25em;
            vertical-align: middle;
        }
        .bonus_col .container-fluid {
            display: table-cell;
            vertical-align: middle;
        }
    } */
`

export const Container = styled.div`
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
    box-sizing: border-box;
    /* color: ${fontColor}; */
    a{
        color: ${fontColor};
        text-decoration: initial;
    }
`

export const CenteredFlex = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const HeaderStyle = styled.div`
    height: 60px;
    background-color: black;
    >div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    h2{
        color: white;
        line-height: 60px;
        margin: 0;
    }
`
export const FooterStyle = styled.div`
    background-color: black;
    color: white;
    margin-top: auto;
    font-size: 0.9em;
    color: ${grayColor};
    a{
        color: ${grayColor};
    }
    >div{
        display: flex;
        flex-wrap: wrap;
        >p{
            width: 100%;
            font-size: 0.9em;
        }
        >div{
            padding: 20px;
            width: 50%;
            img{
                width: 50px;
                margin-right: 10px;
            }
            p:first-of-type img{
                float: left;
            }
            p:last-of-type img{
                height: 30px;
                width: auto;
                margin-top: 10px;
            }

        }
    }
`
export const AgePopupStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
    background-color: rgba(8,11,14,.7);
    text-align: center;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 100000;
    >div{
        border: 1px solid #233140;
        border-radius: 5px;
        >div{
            background-color: #11181f;
            padding: 20px 75px;
            p{
                color: #f08e84;
                text-shadow: 0 2px 1px rgba(0,0,0,.6);
                font-size: 1.1em;
            }
            span{
                color: #698aac;
                margin-bottom: 10px;
            }
            >div{
                display: flex;
                justify-content: center;
                margin-top: 20px;
                button{
                    border: 0;
                    background-color: transparent;
                    padding: 10px 50px;
                    color: #aaa;
                    font-size: 1.2em;
                    :first-of-type{
                        padding: 10px 100px;
                        background-color: #77180e;
                        color: white;
                    }
                    :first-of-type:hover{
                        background-color: #490f09;
                    }
                }
            }
            >a{
                display: inline-block;
                padding-top: 20px;
            }
        }
        >div:first-of-type{
            background-color: #080b0e;
            color: white;
            text-align: center;
            font-weight: 600;
            font-size: 1.3em;
        }
    }
`
export const TopHomeStyle = styled.div`
    background-color: ${grayColor};
    padding: 20px;
    >div{
        display: flex;
        flex-wrap: wrap;
        img{
            width: 80%;
            margin-left: 10%;
        }
    }
    > div > div{
        width: 50%;
        p{
            font-size: 1.2em;
        }
    }
    ${mobile}{
        > div > div{
            width: 100%;
        }
        >div img{
            width: 100%;
            margin-left: 0;
        }
    }
`
export const CategoriesStyle = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    width: 100% !important;
    margin: 25px 0 5px;
    ${mobile}{
        margin: 0 -15px;
        padding: 0 10px;
    }
`
export const CategoryHomeStyle = styled.div`
    /* margin-right: 20px; */
    background-color: ${props => props.selected ? primaryColor : 'white'};
    padding: 15px 10px;
    margin-right: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 1;
    h2{
        font-size: 1em;
        margin: 0;
        margin-left: 10px;
        font-weight: 600;
    }
    >div{
        background-image: url(${props => props.image});
        background-position: center;
        background-size: cover;
        height: 40px;
        width: 40px;
        flex: 0 0 40px;
    }
    ${mobile}{
        flex-direction: column;
        width: 175px;
        flex: 0 0 175px;
        >div{
            height: 125px;
            width: 125px;
            background-size: contain;
            background-repeat: no-repeat;
            order: 2;
            flex: 0 0 125px;
        }
    }
`
export const CategoryDescriptionHomeStyle = styled.div`
    margin: 25px 0;
`
export const SiteTopStyle = styled.div`
    margin: 10px 0;
    display: flex;
    img{
        width: 75px;
        height: 100%;
        margin-right: 10px;
        border-radius: 5px;
    }
    >div{
        display: inline-block;
        h3{
            font-size: 1.2;
            margin: 0;
            margin-bottom: 0.75em;
        }
        >div{
            display: flex;
        }
    }
`
export const SiteCenterStyle = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin: 0 10px 10px;
    >div:first-of-type{
        display: flex;
        >div{
            margin-right: 30px;
        }
    }
    svg{
        display: block;
        margin: auto;
    }
`
export const SiteButtonStyle = styled.a`
    display: block;
    color: white !important;
    border-radius: 10px;
    font-weight: 600;
    background-color: ${primaryColor};
    padding: 10px;
    text-align: center;
    margin: 10px 0;
`

export const SiteHomeStyle = styled.div`
    box-shadow: 0 0 2px gray;
    border-radius: 5px;
    padding: 2px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    >div{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
    }
`
export const TriangleSiteHomeStyle = styled.div`
    position: absolute;
    background-color: ${primaryColor};
    clip-path: polygon(0 0, 100% 0, 0 100%);
    width: 50px;
    height: 50px;
    justify-content: left !important;
    align-items: start !important;
    padding-top: 3px !important;
    >span{
        color: white;
        font-weight: 600;
        font-size: 1.1em;
    }
`
export const ImageSiteHomeStyle = styled.div`
    width: 20%;
    flex-direction: column;
    img{
        width: 150px;
        height: auto;
        min-height: 100px;
    }
    div{
        width: fit-content;
        border-radius: 20px;
        background-color: ${grayColor};
        padding: 7px 15px;
        strong{
            font-size: 1.1em;
            margin-left: 5px;
        }
    }
`
export const BonusHomeStyle = styled.div`
    width: 20%;
    text-align: center;
    padding: 10px 20px !important;
    strong{
        color: ${primaryColor};
    }
`
export const AttributeSiteHomeStyle = styled.div`
    width: 40%;
    flex-wrap: wrap;
    justify-content: start !important;
    padding: 20px 10px 20px 20px !important;
    >div{
        padding-right: 10px;
        width: 50%;
        box-sizing: border-box;
    }
`
export const ButtonSiteHomeStyle = styled.div`
    width: 20%;
    >a{
        position: relative;
        background-color: ${primaryColor};
        color: white;
        font-weight: 600;
        font-size: 1.3em;
        padding: 10px;
        border-radius: 50px;
        width: 100%;
        text-align: center;
        :after{
            content: '';
            border-radius: 50px;
            left: -3px;
            top: -3px;
            border: 2px dotted ${primaryColor};
            position: absolute;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
        }
    }
`
export const BottomSiteHomeStyle = styled(Link)`
    width: 100%;
    background-color: ${grayLightColor};
    padding: 5px;
    font-size: 1.2em;
    padding-left: 10px;
`
export const ReviewStyle = styled.div`
    padding: 20px 0;
    a{
        color: #9d242a;
    }
    .btn, .btn-cta-play-now, .play_now_btn {
        color: white !important;
    }
    h1{
        margin-top: 2rem;
    }
`
export const NotFoundStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
