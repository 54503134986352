import React from 'react'

const PrivacyPage = () => {
    return (
        <div class="body_content container">
            <h2 >Privacy Policy di {window.location.hostname}</h2>

            Questo sito si impegna a salvaguardare la tua privacy.

            Utilizzando questo sito acconsenti al trattamento dei tuoi dati personali come descritto nella presente Informativa sulla privacy.
            <h3 id="indice-dei-contenuti">Indice dei contenuti:</h3>
            <ol>
                <li><a href="/#gdpr-definitions">Definizioni utilizzate in questa Policy</a></li>
                <li><a href="/#gdpr-principles">Principi di protezione dei dati</a></li>
                <li><a href="/#gdpr-rights">Quali diritti hai rispetto ai tuoi dati personali</a></li>
                <li><a href="/#gdpr-information">Quali dati personali raccogliamo</a></li>
                <li><a href="/#gdpr-processing">Come utilizziamo i dati raccolti</a></li>
                <li><a href="/#gdpr-sharing">Chi altro può avere accesso ai tuoi dati</a></li>
                <li><a href="/#gdpr-security">Come mettiamo in sicurezza i tuoi dati</a></li>
                <li><a href="/#gdpr-cookies">Informazioni rispetto ai cookies</a></li>
                <li><a href="/#gdpr-contact">Informazioni di contatto</a></li>
            </ol>
            <h3 id="gdpr-definitions">Definizioni</h3>
            <strong>Dati personali</strong> – qualsiasi informazione relativa a una persona fisica identificata o identificabile.
            <strong>Trattamento</strong> – qualsiasi operazione o insieme di operazioni eseguite su Dati personali o su serie di Dati personali.
            <strong>Interessato</strong> – una persona fisica i cui dati personali sono stati trattati.
            <strong>Bambino</strong> – una persona fisica sotto i 16 anni di età.
            <strong>Noi / noi</strong> (sia maiuscole che non)  – {window.location.hostname}
            <h3 id="gdpr-principles">Principi di protezione dei dati</h3>
            Ci impegniamo a seguire i seguenti principi di protezione dei dati:
            <ul>
                <li>Il trattamento è lecito, equo, trasparente. Le nostre attività di trattamento hanno motivi legittimi. Consideriamo sempre i tuoi diritti prima di elaborare i dati personali. Vi forniremo informazioni riguardanti l’elaborazione su richiesta.</li>
                <li>Il trattamento è limitato allo scopo. Le nostre attività di trattamento corrispondono allo scopo per cui sono stati raccolti i dati personali.</li>
                <li>Il trattamento viene eseguito solo sui dati per cui è stato dato esplicito consenso.</li>
                <li>Faremo del nostro meglio per garantire l’integrità e la riservatezza dei dati.</li>
            </ul>
            <h3 id="gdpr-rights">Diritti rispetto ai propri dati personali</h3>
            L’interessato ha i seguenti diritti:
            <ol>
                <li>Diritto all’informazione – significa che devi sapere se i tuoi dati personali sono in corso di elaborazione; quali dati vengono raccolti, da dove viene ottenuto e perché e da chi viene elaborato.</li>
                <li>Diritto di accesso – significa che hai il diritto di accedere ai dati raccolti da / su di te. Ciò include il diritto di richiedere e ottenere una copia dei dati personali raccolti.</li>
                <li>Diritto di rettifica: ciò significa che hai il diritto di chiedere la rettifica o la cancellazione dei tuoi dati personali che sono inaccurati o incompleti.</li>
                <li>Diritto alla cancellazione: in determinate circostanze è possibile richiedere la cancellazione dei dati personali dai nostri archivi.</li>
                <li>Diritto di limitare il trattamento – nel senso che si applicano determinate condizioni, l’utente ha il diritto di limitare l’elaborazione dei propri dati personali.</li>
                <li>Diritto di opporsi al trattamento: ciò significa che in alcuni casi si ha il diritto di opporsi al trattamento dei dati personali dell’utente, ad esempio nel caso del marketing diretto.</li>
                <li>Diritto di opporsi all’elaborazione automatizzata: ciò significa che in alcuni casi si ha il diritto di opporsi all’elaborazione automatica, inclusa la profilazione; e non essere soggetto a una decisione basata esclusivamente sull’elaborazione automatizzata. Questo diritto è possibile esercitare ogni volta che esiste un risultato della profilazione che produce effetti legali che riguardano o che influiscono in modo significativo su di te.</li>
                <li>Diritto alla portabilità dei dati: avete il diritto di ottenere i vostri dati personali in un formato leggibile dalla macchina o, se possibile, come trasferimento diretto da un processore a un altro.</li>
                <li>Diritto di sporgere un reclamo – se non sei soddisfatto del modo in cui è stata gestita la tua richiesta, contattaci.</li>
                <li>Chiedere aiuto all’<a href="/#gdpr-contact">autorità di controllo</a> – nel senso che hai diritto all’aiuto di un’autorità di vigilanza e il diritto ad altri rimedi legali come la richiesta di risarcimento danni.</li>
                <li>Diritto di revoca del consenso – l’utente ha il diritto di revocare qualsiasi consenso per l’elaborazione dei propri dati personali.</li>
            </ol>
            <h3 id="gdpr-information">Dati che raccogliamo</h3>
            <strong>Informazioni che ci hai fornito</strong>
            Gli unici dati raccolti sono quelli inviati in maniera volontaria attraverso il modulo di contatto presente nella pagina CONTATTI di questo sito.

            <strong>Informazioni raccolte automaticamente su di te</strong>
            Non ci sono attività dell’utente registrate all’interno di questo sito.

            <strong>Informazioni dai nostri partner</strong>
            Non condividiamo nessun dato con nessuno e nessuno li condivide con noi.

            <strong>Informazioni disponibili al pubblico</strong>
            Non rendiamo pubblico nessuno dei dati inviati attraverso il nostro sito.
            <h3 id="gdpr-processing">Trattamento dei dati inviati dall’utente attraverso il modulo di contatto</h3>
            I dati inviati attraverso il modulo di contatto (nome e indirizzo email) vengono utilizzati al solo fine di risposta alle vostre richieste.
            <h3 id="gdpr-processing">Conservazione dei dati inviati dall’utente attraverso il modulo di contatto</h3>
            I dati inviati attraverso il modulo di contatto (nome e indirizzo email) non vengono conservati dopo la risposta alle vostre richieste.
            <h3 id="gdpr-security">Sicurezza</h3>
            Pur non conservando alcun dato all’interno di questo sito, lo stesso dispone di un protocollo HTTPS (protocollo sicuro di comunicazione e trasferimento).

            Ci impegniamo a notificare alle autorità competenti eventuali violazioni a questo sito. Ti informeremo inoltre se sussiste una minaccia per i tuoi diritti o interessi. Faremo tutto quanto ragionevolmente possibile per prevenire violazioni della sicurezza e assistere le autorità in caso di violazioni.
            <h3 id="bambini-minori-di-16-anni">Bambini (minori di 16 anni)</h3>
            Non intendiamo raccogliere o raccogliere consapevolmente informazioni dai bambini. Non indirizziamo i bambini ai nostri servizi.
            <h3 id="cookies">Cookies</h3>
            Utilizziamo cookie e / o tecnologie simili. Questo è fatto al fine di personalizzare e migliorare la tua esperienza con noi.

            Un cookie è un piccolo file di testo memorizzato sul tuo computer. I cookie memorizzano le informazioni che vengono utilizzate per aiutare a far funzionare i siti. Solo noi possiamo accedere ai cookie creati dal nostro sito web. Puoi controllare i tuoi cookie a livello di browser. La scelta di disabilitare i cookie potrebbe ostacolare l’utilizzo di determinate funzioni.

            <strong>Utilizziamo i cookie per i seguenti scopi:</strong>
            <ul>
                <li>Cookie necessari: questi cookie sono necessari per poter utilizzare alcune funzionalità importanti sul nostro sito Web, come ad esempio l’accesso. Questi cookie non raccolgono alcuna informazione personale.</li>
                <li>Cookie analitici: questi cookie sono utilizzati per tracciare l’utilizzo e le prestazioni del nostro sito Web e dei nostri servizind services</li>
            </ul>
            È possibile rimuovere i cookie memorizzati nel computer tramite le impostazioni del browser. In alternativa, puoi controllare alcuni cookie di terze parti utilizzando una piattaforma di miglioramento della privacy come<a href="http://optout.aboutads.info/#!/" target="_blank" rel="noreferrer noopener"> optout.aboutads.info</a> o <a href="http://www.youronlinechoices.com/" target="_blank" rel="noreferrer noopener">youronlinechoices.com</a>. Per maggiori informazioni sui cookie visita <a href="http://www.allaboutcookies.org/" target="_blank" rel="noreferrer noopener">allaboutcookies.org</a>.

            Utilizziamo Google Analytics per misurare il traffico sul nostro sito web. Google ha una propria Informativa sulla privacy che puoi esaminare <a href="https://support.google.com/analytics/answer/6004245" target="_blank" rel="noreferrer noopener">qui</a>. Se desideri disattivare il tracciamento di Google Analytics, visita il <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer noopener">Google Analytics opt-out page</a>.

            Utilizziamo Facebook Pixel per la creazione di campagne di marketing con lo scopo di  tracciare il traffico presente sul sito web con l’obiettivo di creare campagne di remarketing. Facebook ha una propria informativa sulla privacy che puoi esaminare <a href="https://it-it.facebook.com/policy.php">qui</a>.
            <h3 id="gdpr-contact">Informazioni di contatto</h3>
            La responsabilità di questa Privacy Policy è di {window.location.hostname}
            <h3 id="gdpr-changes">Cambiamenti a questa Privacy Policy</h3>
            Ci riserviamo il diritto di modificare questa Privacy Policy.

            L’ultima modifica è stata fatta in data 08/06/2022

            <hr class="wp-block-separator" />

            <h4 id="riferimenti-legali">Riferimenti legali</h4>
            Avviso agli Utenti europei: la presente informativa privacy è redatta in adempimento degli obblighi previsti dall’Art. 10 della Direttiva n. 95/46/CE, nonché a quanto previsto dalla Direttiva 2002/58/CE, come aggiornata dalla Direttiva 2009/136/CE, in materia di Cookie e al Regolamento Europeo in materia di Protezione dei Dati Personali (2016/679).

            Questa informativa privacy riguarda esclusivamente questo sito web.

        </div>
    )
}

export default PrivacyPage