import React, { useState, useEffect, useRef } from 'react';
import queryString from 'query-string';

import { InfinitySpin } from  'react-loader-spinner'
import { CategoriesStyle, CategoryDescriptionHomeStyle, CenteredFlex, Container, primaryColor, TopHomeStyle } from '../App.Style';
import CategoryHome from '../components/CategoryHome';
import SiteHome from '../components/SiteHome';


const HomePage = props => {
    const [data, setData] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(0);
    const listSitesRef = useRef(null);

    useEffect(() => {
        fetch('https://best4apps.com/api/room-full/', {
            method: 'POST',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: queryString.stringify({
                'slug': 'gamblingit'
            }),
        })
        .then(response => response.json())
        .then(json => {
            setData(json)
            console.log(json)
        })
    }, []);
    
    useEffect(() => {
        listSitesRef.current?.scrollToOffset({ offset: 0 })
    }, [selectedRoom]);

    
    if (!data){
        return  <CenteredFlex>
            <InfinitySpin 
                width='200'
                color={primaryColor}
            />
        </CenteredFlex>
    }

    const categories = data.liste.map((category, i)=>{
        return <CategoryHome setSelectedRoom={setSelectedRoom} key={i} index={i} selected={i==selectedRoom} element={category} />
    })

    const sites = data.liste[selectedRoom].reviews.map((site, i)=>{
        return <SiteHome key={selectedRoom+site.nome_sito} index={i} element={site} />
    })
    
    return (
        <>
        <TopHomeStyle>
            <Container>
                <div>
                    <h1>Migliori Casinò Online in Italia</h1>
                    <p>Su miglioribonus.net troverete recensioni imparziali sui siti di gioco online come casinò, scommesse e poker presenti in Italia, oltre a consigli, suggerimenti e bonus esclusivi dei migliori siti per giocare online a soldi veri.</p>
                </div>
                <div><img src='/img/online-casino-slot.png' alt='Online Casino' /></div>
                <CategoriesStyle>
                    {categories}
                </CategoriesStyle>
            </Container>
        </TopHomeStyle>
        <Container>
            <CategoryDescriptionHomeStyle dangerouslySetInnerHTML={{__html: data.liste[selectedRoom].descrizione}}></CategoryDescriptionHomeStyle>
            {sites}
        </Container>
        </>
        // <View style={styles.container}>
        //     <View style={styles.categoryView}>
        //     <FlatList 
        //         style={styles.category} 
        //         horizontal={true}
        //         data={data.liste}
        //         renderItem={({item, index}) => <CategoryHome setSelectedRoom={setSelectedRoom} index={index} selected={index==selectedRoom} element={item} />}
        //         keyExtractor={item => item.titolo}
        //         />
        //     </View>
        //     <FlatList 
        //         style={styles.sites} 
        //         data={data.liste[selectedRoom].reviews}
        //         renderItem={({item}) => <SiteHome element={item} navigation={props.navigation} />}
        //         keyExtractor={item => item.id}
        //         ref={listSitesRef}
        //     />
        // </View>
    );
}


  

export default HomePage;
