import React from 'react'

const CookiePage = () => {
    return (
        <div class='body_content container'>
            <p><strong>Cookie Policy</strong><br></br>
                La presente informativa, ai sensi dell’art. 13 del d.lgs. 196/2003 (di seguito, “<strong>Codice Privacy</strong>”) descrive le modalità di gestione del presente sito web di titolarità di&nbsp;Studio Meno Uno in riferimento al trattamento dei dati personali degli utenti che lo consultano, anche ai sensi di quanto richiesto dal Provvedimento del Garante della Privacy del 8.05.2014 “Individuazione delle modalità semplificate per l’informativa e l’acquisizione del consenso per l’uso dei cookie” (di seguito, “<strong>Provvedimento</strong>”).<br></br>
                L’informativa è resa soltanto per il sito sopra menzionato e non anche per altri siti web o sezioni/pagine/spazi di titolarità di terzi – eventualmente consultati dall’utente tramite appositi link presenti all’interno dello stesso.</p>
            <p><strong>1. TIPOLOGIA DI DATI TRATTATI E FINALITA’ DEL TRATTAMENTO</strong><br></br>
                <strong>1.1. Dati di navigazione</strong><br></br>
                I sistemi informatici e gli applicativi dedicati al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.<br></br>
                Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti che si connettono al sito. Tra i dati raccolti sono compresi gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.<br></br>
                Questi dati vengono trattati, per il tempo necessario al raggiungimento della finalità per cui sono raccolti, al solo fine di ricavare informazioni statistiche anonime sull’uso del sito (accessi allo stesso) e per controllarne il regolare funzionamento.<br></br>
                I dati potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito.</p>
            <p><strong>1.2. Dati forniti volontariamente dall’utente</strong><br></br>
                Qualora agli utenti del presente sito sia richiesto, per accedere a determinati servizi, di conferire propri dati personali, sarà previamente rilasciata, nelle pagine relative ai singoli servizi, apposita e dettagliata Informativa sul relativo trattamento ai sensi dell’art. 13 del&nbsp;<a href="http://www.garanteprivacy.it/" target="_blank" rel="noopener noreferrer">Codice</a>&nbsp;Privacy che specificherà limiti, finalità e modalità del trattamento stesso.</p>
            <p><strong>1.3. Cookies</strong><br></br>
                <strong><em>Cosa sono i cookie?</em></strong><br></br>
                I cookie sono piccoli file di testo che vengono inviati dal sito web visitato dall’utente sul dispositivo dell’utente (solitamente al browser), dove vengono memorizzati in modo da poter riconoscere tale dispositivo alla successiva visita. Ad ogni visita successiva, infatti, i cookie sono reinviati dal dispositivo dell’utente al sito.<br></br>
                Ciascun cookie contiene generalmente: il nome del server da cui il cookie è stato inviato; la scadenza ed un valore, solitamente un numero unico generato a caso dal computer Il server del sito web che trasferisce il cookie utilizza questo numero per riconoscervi quando tornate a visitare un sito o navigate da una pagina all’altra.<br></br>
                I cookie possono essere istallati non solo dallo stesso gestore del sito visitato dall’utente (<strong>cookie di prima parte</strong>), ma anche da un sito diverso che istalla cookie per il tramite del primo sito (<strong>cookie di terze parti</strong>) ed è in grado di riconoscerli. Questo accade perché sul sito visitato possono essere presenti elementi (immagini, mappe, suoni, link a pagine web di altri domini, etc) che risiedono su server diversi da quello del sito visitato.<br></br>
                In base alla finalità, i cookie si distinguono in&nbsp;<strong>cookie tecnici</strong>&nbsp;ed in&nbsp;<strong>cookie di profilazione</strong>.<br></br>
                I cookie tecnici sono quelli utilizzati al solo fine di “<em>effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria al fornitore di un servizio della società dell’informazione esplicitamente richiesto dall’abbonato o dall’utente a erogare tale servizio</em>” (cfr. art. 122, co. 1, del Codice Privacy, come modificato dal d.lgs. 69/2012). In particolare, tali cookie sono solitamente usati per consentire una navigazione efficiente tra le pagine, memorizzare le preferenze (lingua, paese, ecc) &nbsp;degli utenti, effettuare autenticazioni informatiche, gestire il carrello degli acquisti o&nbsp; consentire acquisti online, etc. Alcuni di questi cookie (detti essenziali o&nbsp;<em>strictly necessary</em>) abilitano funzioni senza le quali non sarebbe possibile effettuare alcune operazioni. Ai sensi del suddetto art. 122, co. 1 del Codice Privacy l’utilizzo dei cookie tecnici non richiede il consenso degli utenti.<br></br>
                Ai cookie tecnici sono assimilati i&nbsp;<strong>cookie cd. analytics&nbsp;</strong>se utilizzati direttamente dal gestore del sito ai&nbsp; per raccogliere informazioni aggregate sul numero degli utenti e su come questi visitano il sito stesso. Tali cookie consentono ai titolari e/o ai gestori dei siti web di capire come gli utenti interagiscono con i contenuti del sito ai fini di ottimizzazione dello stesso.<br></br>
                I&nbsp;<strong>cookie di profilazione</strong>&nbsp;servono a tracciare la navigazione dell’utente, analizzare il suo comportamento ai fini marketing e creare profili sui suoi gusti, abitudini, scelte, etc in modo da trasmettere messaggi pubblicitari mirati in relazione agli interessi dell’utente stesso ed in linea con le preferenze da questi manifestati nella navigazione on line. Tali cookie possono essere istallati sul terminale dell’utente solo se questo abbia espresso il proprio consenso con le modalità semplificate&nbsp; indicate nel Provvedimento.<br></br>
                In base alla loro durata, i cookie si distinguono in&nbsp;<strong>persistenti</strong>, che rimangono memorizzati, fino alla loro scadenza, sul dispositivo dell’utente, salva rimozione da parte di quest’ultimo, e&nbsp;<strong>di sessione</strong>, che non vengono memorizzati in modo persistente sul dispositivo dell’utente e svaniscono con la chiusura del browser.</p>
            <p><strong><em><u>Quali cookie sono utilizzati dal presente sito?&nbsp;</u></em></strong><br></br>
                <strong><em>a. Cookie tecnici.</em></strong><strong>&nbsp;</strong><br></br>
                Il presente sito fa uso di&nbsp;<strong>cookie tecnici</strong>, istallati dal sito stesso al fine di monitorare il funzionamento del sito e consentire una navigazione efficiente sullo stesso.<br></br>
                Di seguito, sono riportati, per ciascun cookie tecnico che potrebbe essere utilizzato, il nome, la finalità di utilizzo e la tipologia/durata.</p>
            <div>
                <table width="100%" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td width="32%" valign="bottom"><strong>NOME</strong></td>
                            <td width="39%"><strong>FINALITÀ</strong></td>
                            <td width="28%"><strong>TIPOLOGIA/DURATA</strong></td>
                        </tr>
                        <tr>
                            <td>cookielawinfo-checkbox-advertisement</td>
                            <td>Set by the GDPR Cookie Consent plugin, this cookie is used to record the user consent for the cookies in the “Advertisement” category .</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>cookielawinfo-checkbox-analytics</td>
                            <td>This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category “Analytics”.</td>
                            <td>11 months</td>
                        </tr>
                        <tr>
                            <td>cookielawinfo-checkbox-functional</td>
                            <td>The cookie is set by GDPR cookie consent to record the user consent for the cookies in the category “Functional”.</td>
                            <td>11 months</td>
                        </tr>
                        <tr>
                            <td>cookielawinfo-checkbox-necessary</td>
                            <td>This cookie is set by GDPR Cookie Consent plugin. The cookies is used to store the user consent for the cookies in the category “Necessary”.</td>
                            <td>11 months</td>
                        </tr>
                        <tr>
                            <td>cookielawinfo-checkbox-others</td>
                            <td>This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category “Other.</td>
                            <td>11 months</td>
                        </tr>
                        <tr>
                            <td>cookielawinfo-checkbox-performance</td>
                            <td>This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category “Performance”.</td>
                            <td>11 months</td>
                        </tr>
                        <tr>
                            <td>CookieLawInfoConsent</td>
                            <td>Records the default button state of the corresponding category &amp; the status of CCPA. It works only in coordination with the primary cookie.</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>elementor</td>
                            <td>This cookie is used by the website’s WordPress theme. It allows the website owner to implement or change the website’s content in real-time.</td>
                            <td>never</td>
                        </tr>
                        <tr>
                            <td>viewed_cookie_policy</td>
                            <td>The cookie is set by the GDPR Cookie Consent plugin and is used to store whether or not user has consented to the use of cookies. It does not store any personal data.</td>
                            <td>11 months</td>
                        </tr>
                        <tr>
                            <td width="32%" valign="bottom">PHPSESSID</td>
                            <td width="39%" valign="top">cookie di sessione</td>
                            <td width="28%" valign="top">End of browser session</td>
                        </tr>
                        <tr>
                            <td width="32%" valign="bottom">_icl_current_language=en</td>
                            <td width="39%" valign="top">gestione lingue</td>
                            <td width="28%" valign="top">End of browser session</td>
                        </tr>
                        <tr>
                            <td width="32%" valign="bottom">wp_woocommerce_session_</td>
                            <td width="39%" valign="top">cookie di sessione modulo e-commerce</td>
                            <td width="28%" valign="top">End of browser session</td>
                        </tr>
                        <tr>
                            <td width="32%" valign="bottom">of_current_opt</td>
                            <td width="39%" valign="top">cookie di gestione del cms</td>
                            <td width="28%" valign="top">End of browser session</td>
                        </tr>
                        <tr>
                            <td width="32%" valign="bottom">wp-settings-time-</td>
                            <td width="39%" valign="top">cookie di gestione del cms</td>
                            <td width="28%" valign="top">1 year</td>
                        </tr>
                        <tr>
                            <td width="32%" valign="bottom">wp-settings-</td>
                            <td width="39%" valign="top">cookie di gestione del cms</td>
                            <td width="28%" valign="top">1 year</td>
                        </tr>
                        <tr>
                            <td width="32%">mc4wp_email</td>
                            <td width="39%" valign="top">cookie di gestione del cms in relazione a Mailchimp</td>
                            <td width="28%" valign="top">1 year</td>
                        </tr>
                    </tbody>
                </table>
            </div><p>I suddetti cookie non possono essere disabilitati utilizzando le funzioni di questo sito web, ma possono essere disattivati tramite le impostazioni del proprio browser in qualsiasi istante (con le modalità di seguito indicate). La loro disattivazione potrebbe<strong>&nbsp;</strong>precludere la fruizione ottimale di alcune aree del sito<strong>.&nbsp;</strong></p>
            <p><strong><em>b. Cookie di performance e analytics</em></strong><strong>&nbsp;</strong><br></br>
                Sono utilizzati anche cookie&nbsp;<strong>analytics&nbsp;</strong>al fine di analizzare statisticamente &nbsp;il traffico sul sito, conteggiare gli accessi o le visite al sito stesso e consentire al titolare, anche grazie alle stime sui numeri e sui modelli di consumo, di migliorarne la struttura, le logiche di navigazione e i contenuti, di adattarlo agli interessi degli utenti, di accelerare le ricerche, ecc.<br></br>
                Di seguito, sono riportati, per ciascun cookie analytics utilizzato, il nome, la finalità di utilizzo, la tipologia/durata e l’origine.</p>
            <div>
                <table width="100%" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr><td width="16%"><strong>NOME</strong></td>
                            <td width="47%"><strong>FINALITÀ</strong></td>
                            <td width="17%"><strong>TIPOLOGIA/DURATA</strong></td>
                            <td width="18%"><strong>ORIGINE</strong></td>
                        </tr>
                        <tr>
                            <td colspan="4" width="100%" valign="bottom"><strong>GOOGLE ANALYTICS</strong></td>
                        </tr>
                        <tr>
                            <td width="16%" valign="top">_ga</td>
                            <td rowspan="10" width="47%" valign="top"><p align="center">Raccogliere informazioni statistiche circa l’utilizzo del sito da parte degli utenti, in particolare, sapere quanti sono ritornati e da dove sono venuti, quanti sono arrivati dai motori di ricerca, quanti sono arrivati direttamente al nostro URL, sapere quali pagine hanno visitato etc..</p>
                            </td>
                            <td width="17%" valign="top">2 years</td>
                            <td rowspan="10" width="18%" valign="top"><p align="center">Google<br></br>
                                per maggiori informazioni sul servizio Google Analytics si veda http://www.google.it/analytics e https://www.google.com/analytics/learn/privacy.html?hl=it</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="16%" valign="top">_gat</td>
                            <td width="17%" valign="top">10 minutes</td>
                        </tr>
                        <tr>
                            <td width="16%" valign="top">__utma</td>
                            <td width="17%" valign="top">2 years from set/update</td>
                        </tr>
                        <tr>
                            <td width="16%" valign="top">__utmt</td>
                            <td width="17%" valign="top">10 minutes</td>
                        </tr>
                        <tr>
                            <td width="16%" valign="top">__utmb</td>
                            <td width="17%" valign="top">30 mins from set/update</td>
                        </tr>
                        <tr>
                            <td width="16%" valign="top">__utmc</td>
                            <td width="17%" valign="top">End of browser session</td>
                        </tr>
                        <tr>
                            <td width="16%" valign="top">__utmz</td>
                            <td width="17%" valign="top">6 months from set/update</td>
                        </tr>
                        <tr>
                            <td width="16%" valign="top">__utmv</td>
                            <td width="17%" valign="top">2 years from set/update</td>
                        </tr>
                        <tr>
                            <td width="16%" valign="top">__utmx</td>
                            <td width="17%" valign="top">18 months</td>
                        </tr>
                        <tr>
                            <td width="16%" valign="top">__utmxx</td>
                            <td width="17%" valign="top">18 months</td>
                        </tr>
                    </tbody>
                </table>
            </div><p><strong>&nbsp;</strong></p>
            <p><strong><em>c. Cookie di profilazione</em></strong><strong>.</strong><br></br>
                <strong>Cookie di profilazione di terze parti</strong><br></br>
                Nel corso della navigazione sul presente sito, l’utente potrebbe ricevere sul suo terminale cookie di profilazione installati e gestiti da terze parti al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dall’utente nell’ambito della sua navigazione in rete. Ciò accade perché, come sopra indicato, sul sito possono essere presenti elementi che risiedono su server diversi da quello sul quale si trova il sito visitato. Laddove terze parti impostino cookie mentre visitate il presente sito, ottengono informazioni relative al fatto che gli utenti lo hanno visitato. Maggiori informazioni sull’impiego dei cookie sono disponibili accedendo al link di seguito indicato al sito della terza parte. Il diniego del consenso all’utilizzo dei cookie di profilazione di terze parti non interferisce sulla possibilità di accedere al sito.<br></br>
                <strong>Di seguito,&nbsp;</strong><strong>riportiamo il nome dei cookie di terza parte che potrebbero essere istallati da quest’ultima tramite il sito, lo scopo, il nome della terza parte ed il link al sito di quest’ultima.</strong></p>
            <div>
                <table width="100%" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr><td width="21%" valign="bottom"><strong>Nome</strong></td>
                            <td width="36%" valign="bottom"><strong>Finalità</strong></td>
                            <td width="14%" valign="bottom"><strong>Terza Parte</strong></td>
                            <td width="28%" valign="bottom"><strong>Link al sito ove visionare l’informativa privacy della terza parte</strong></td>
                        </tr>
                        <tr>
                            <td width="21%" valign="top">locale</td>
                            <td rowspan="8" width="36%" valign="top"><p align="center">Facebook usa i cookie per diversi motivi, ad esempio per poterti mostrare contenuti e inserzioni più pertinenti, per migliorare i prodotti e servizi e per garantire la sicurezza nei Servizi.</p>
                            </td>
                            <td rowspan="8" width="14%" valign="top"><p align="center">Facebook</p>
                            </td>
                            <td rowspan="8" width="28%" valign="top"><p align="center">Per informazioni e gestione delle preferenze<br></br>
                                https://www.facebook.com/help/cookies/</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="21%" valign="top">datr</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="top">xs</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="top">s</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="top">lu</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="top">fr</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="top">csm</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="top">c_user</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">&nbsp;</td>
                            <td width="36%" valign="bottom">&nbsp;</td>
                            <td width="14%" valign="bottom">&nbsp;</td>
                            <td width="28%" valign="bottom">&nbsp;</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">__utma</td>
                            <td rowspan="13" width="36%" valign="top"><p align="center">Twitter usa queste tecnologie per fornire, valutare e migliorare i propri servizi sotto diversi punti di vista.</p>
                            </td>
                            <td rowspan="13" width="14%" valign="top"><p align="center">Twitter</p>
                            </td>
                            <td rowspan="13" width="28%" valign="top"><p align="center">Per informazioni e gestione delle preferenze: https://support.twitter.com/articles/20170519-uso-dei-cookie-e-di-altre-tecnologie-simili-da-parte-di-twitter</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">__utmv</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">__utmz</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">auth_token</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">guest_id</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">pid</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">remember_checked</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">remember_checked_on</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">secure_session</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">twll</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">dnt</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">eu_cn</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">external_referer</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">&nbsp;</td>
                            <td width="36%" valign="bottom">&nbsp;</td>
                            <td width="14%" valign="bottom">&nbsp;</td>
                            <td width="28%" valign="bottom">&nbsp;</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">PREF</td>
                            <td rowspan="20" width="36%" valign="top"><p align="center">Google utilizza cookie per pubblicare annunci pubblicitari sulla base delle precedenti visite effettuate dagli utenti sul presente sito web.</p>
                            </td>
                            <td rowspan="20" width="14%" valign="top"><p align="center">Google</p>
                            </td>
                            <td rowspan="20" width="28%" valign="top"><p align="center">Per informazioni su come disattivare l’utilizzo dei cookie da parte di Google, visionare la pagina Preferenze annunci di Google alla URL http://www.google.com/settings/ads.<br></br>
                                In alternativa, visionare la pagina di disattivazione della Network Advertising Initiative http://www.networkadvertising.org/managing/opt_out.asp<br></br>
                                Gli utenti che intendono impedire l’istallazione sul proprio terminale dei cookie relativi a Google Analytics, possono scaricare e installare all’indirizzo&nbsp;<a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout</a>&nbsp;il componente aggiuntivo del browser per la disattivazione di Google Analytics, sviluppato da Google, seguendo le istruzioni fornite</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">lbcs</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">ACCOUNT_CHOOSER</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">APISID</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">SAPISID</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">HSID</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">AID</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">NID</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">SID</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">id</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">_zlcmid</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">_gat</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">&nbsp;_drt_</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">__gads</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">exchange_uid</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">FLC</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">_ga_mftool</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">TAID</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">OGPC</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">GAPS</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">&nbsp;</td>
                            <td width="36%" valign="top">&nbsp;</td>
                            <td width="14%" valign="top">&nbsp;</td>
                            <td width="28%" valign="top">&nbsp;</td>
                        </tr>
                        <tr>
                            <td width="21%" valign="bottom">__utmb</td>
                            <td rowspan="2" width="36%" valign="top"><p align="center">Youtube utilizza i cookie anche quando l’utente interagisce con i servizi offerti ai partner, ad esempio servizi pubblicitari o funzioni di Google (proprietario di Youtube) che potrebbero venire visualizzate su altri siti.</p>
                            </td>
                            <td rowspan="2" width="14%" valign="top"><p align="center">Youtube</p>
                            </td>
                            <td rowspan="2" width="28%" valign="top"><p align="center">Per informazioni e gestione delle preferenze<br></br>
                                https://www.youtube.com/?hl=it&amp;gl=IT</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="21%">&nbsp;__atuvc</td>
                        </tr>
                    </tbody>
                </table>
            </div><p>Si segnala in ogni caso che gli utenti possono gestire le proprie preferenze sulla pubblicità comportamentale online (cd. “on line behavioural advertising”) tramite il sito&nbsp;<a href="http://www.youronlinechoices.com/it" target="_blank" rel="noopener noreferrer">www.youronlinechoices.com/it</a>, in cui sono elencati i principali provider di pubblicità comportamentale.&nbsp; Tramite tali sito, gli utenti possono disattivare o attivare tutte le società o in alternativa regolare le proprie preferenze individualmente per ogni società.</p>
            <p><strong><em><u>Come si disattivano i cookie?</u></em></strong><br></br>
                Di default quasi tutti i browser web sono impostati per accettare automaticamente i cookie. È&nbsp; &nbsp;comunque possibile modificare tale configurazione predefinita tramite le impostazioni del browser. La disabilitazione/il blocco dei cookies o la loro cancellazione potrebbe però precludere la fruizione ottimale di alcune aree del sito, impedire l’utilizzo di alcuni servizi e rendere la navigazione più lenta.<br></br>
                La configurazione della gestione dei cookie dipende dal browser utilizzato. Solitamente, la configurazione dei cookie è effettuata dal menu “Preferenze”, “Strumenti” o “Opzioni”.<br></br>
                Si riportano di seguito i link alle guide per le gestione dei cookie dei principali browser:<br></br>
                Internet Explorer:&nbsp;<a href="http://support.microsoft.com/kb/278835" target="_blank" rel="noopener noreferrer">http://support.microsoft.com/kb/278835</a><br></br>
                Internet Explorer [versione mobile]:&nbsp;<a href="http://www.windowsphone.com/en-us/how-to/wp7/web/changing-privacy-and-other-browser-settings" target="_blank" rel="noopener noreferrer">http://www.windowsphone.com/en-us/how-to/wp7/web/changing-privacy-and-other-browser-settings</a><br></br>
                Chrome:&nbsp;<a href="http://support.google.com/chrome/bin/answer.py?hl=en-GB&amp;answer=95647" target="_blank" rel="noopener noreferrer">http://support.google.com/chrome/bin/answer.py?hl=en-GB&amp;answer=95647</a><br></br>
                Safari:&nbsp;<a href="https://support.apple.com/it-it/HT201265" target="_blank" rel="noopener noreferrer">https://support.apple.com/it-it/HT201265</a><br></br>
                Safari [versione mobile]:&nbsp;<a href="http://support.apple.com/kb/HT1677" target="_blank" rel="noopener noreferrer">http://support.apple.com/kb/HT1677</a><br></br>
                Firefox:&nbsp;<a href="http://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies" target="_blank" rel="noopener noreferrer">http://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies</a><br></br>
                Android:&nbsp;<a href="https://support.google.com/chrome/answer/95647?hl=it&amp;co=GENIE.Platform%3DAndroid" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/95647?hl=it&amp;co=GENIE.Platform%3DAndroid</a></p>
            <p><strong>2. MODALITA’ DEL TRATTAMENTO</strong><br></br>
                Il trattamento dei dati personali viene effettuato attraverso strumenti automatizzati (ad es. utilizzando procedure e supporti elettronici) e/o manualmente (ad es. su supporto cartaceo) per il tempo strettamente necessario a conseguire gli scopi per i quali i dati sono stati raccolti e, comunque, in conformità alle disposizioni normative vigenti in materia.</p>
            <p><strong>3. TITOLARE DEL TRATTAMENTO</strong><br></br>
                Il Titolare del trattamento dei dati personali è Studio Meno Uno</p>
            <p><strong>4. DIRITTI DEGLI INTERESSATI</strong><br></br>
                I soggetti cui si riferiscono i dati personali hanno il diritto, in qualunque momento, di ottenere la conferma dell’esistenza o meno dei medesimi dati, di conoscerne il contenuto e l’origine, verificarne l’esattezza o chiederne l’integrazione o l’aggiornamento, oppure la rettificazione ai sensi dell’art. 7 del Codice Privacy. Ai sensi del medesimo articolo gli interessati hanno altresì il diritto di chiedere la cancellazione, la trasformazione in forma anonima o il blocco dei dati che li riguardano trattati in violazione di legge, nonché di opporsi in ogni caso, per motivi legittimi, al loro trattamento.<br></br>
                Per qualunque informazione in merito al trattamento dei dati, nonché per l’esercizio dei diritti ex.&nbsp;<a title="Codice in materia di protezione dei dati personali" href="http://www.garanteprivacy.it/garante/navig/jsp/index.jsp?folderpath=Normativa%2FItaliana%2FIl+Codice+in+materia+di+protezione+dei+dati+personali" target="_blank" rel="noopener noreferrer">art 7 del Codice</a>&nbsp;Privacy, gli utenti possono inoltrare apposita richiesta (anche tramite posta elettronica) agli indirizzi indicati on line nella pagina dei Contatti raggiungibile dal footer del sito.</p>
        </div>

    )
}

export default CookiePage