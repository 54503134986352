import * as React from 'react';

import { CategoryHomeStyle } from '../App.Style';


const CategoryHome = props => {
    const category = props.element
    let styleSelected = {}
    if (props.selected){
        styleSelected = {backgroundColor:'orange'}
    }

    return (
      <CategoryHomeStyle selected={props.selected} onClick={()=>props.setSelectedRoom(props.index)} image={'https://best4apps.com'+category.icon }>
        <div></div>
        <h2>{category.titolo}</h2>
      </CategoryHomeStyle>
    );
}


export default CategoryHome;
